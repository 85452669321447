import _ from 'lodash'

export const ErrorName = {
  FailedToSaveSite: 'FailedToSaveSite',
  FailedToCallApi: 'FailedToCallApi',
  FailedToHandleEvent: 'FailedToHandleEvent',
  FailedToExtractFormId: 'FailedToExtractFormId',
  FailedToFetchFormData: 'FailedToFetchFormData',
  FailedToSendEmailConfig: 'FailedToSendEmailConfig',
  Permissions: 'NoPermissions',
  FailedToHandleAction: 'FailedToHandleAction',
}

export type FormError =
  | FailedToSaveSiteError
  | FailedToCallApiError
  | FailedHandleEventError
  | FailedToExtractFormIdError
  | FailedToHandleActionError
  | FailedToSendEmailConfigError

export class FailedToSaveSiteError extends Error {
  public readonly data

  constructor(error) {
    const description = _.get(error, 'documentServices.errorDescription')
    const errorMessage = _.get(error, 'error')
    const errorType = _.get(error, 'document.errorType')
    super(`Failed to save site: ${description || errorMessage || errorType}`)
    this.name = ErrorName.FailedToSaveSite
    this.data = error
  }
}

export class FailedToCallApiError extends Error {
  public readonly data

  constructor(funcName, error) {
    const httpStatus = _.get(error, 'httpStatus')
    const message = httpStatus
      ? `Failed to call api: ${funcName} with status code ${httpStatus}`
      : `Failed to call api: ${funcName}`
    super(message)
    this.name = ErrorName.FailedToCallApi
    this.data = error
  }
}

export class FailedHandleEventError extends Error {
  public readonly data
  public readonly eventPayload

  constructor(eventType, eventPayload, error) {
    super(`Failed to handle event: ${eventType}`)
    this.name = ErrorName.FailedToHandleEvent
    this.data = error
    this.eventPayload = eventPayload
  }
}

export class FailedToExtractFormIdError extends Error {
  constructor() {
    super('Failed to extract formId')
    this.name = ErrorName.FailedToExtractFormId
  }
}

export class FailedToFetchFormDataError extends Error {
  public readonly data

  constructor(error: Error) {
    super('Failed to fetch form data')
    this.name = ErrorName.FailedToFetchFormData
    this.data = error
  }
}

export class FailedToSendEmailConfigError extends Error {
  public readonly data

  constructor(error: Error) {
    super('Failed to send email config')
    this.name = ErrorName.FailedToSendEmailConfig
    this.data = error
  }
}

export class FailedToHandleActionError extends Error {
  public readonly data
  public readonly eventPayload

  constructor(type: string, eventPayload, error) {
    const message = error instanceof Error ? `${error?.name}: ${error?.message}` : null
    super(`Failed to handle action ${type}${message ? ` with error: ${message}` : ''}`)

    this.name = ErrorName.FailedToHandleAction
    this.eventPayload = eventPayload
    this.data = error
  }
}
